import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Divider,
  Box,
} from '@mui/material';
import { HealthAndSafety, QuestionMark } from '@mui/icons-material';
import BuildIcon from '@mui/icons-material/Build';


const AboutUs = ({ open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
      <DialogTitle sx={{ bgcolor: '#009688', color: '#fff', textAlign: 'center' }}>
        About Us
      </DialogTitle>
      <DialogContent sx={{ bgcolor: '#f0f4f5', padding: 3 }}>
        <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', color: '#333' }}>
          <BuildIcon sx={{ verticalAlign: 'middle', marginRight: 1 }} /> How Does Robur Work?
        </Typography>
        <Typography paragraph sx={{ lineHeight: 1.6 }}>
          Robur works by using cutting-edge generative AI to analyze meal images and provide immediate feedback on their nutritional content, including calorie count, macronutrients, and micronutrients. By aligning your meal choices with your dietary goals, Robur helps users make informed decisions. Its intuitive design allows users to track meals over time, manage their food budget, and track workouts, with future updates bringing even more features like fitness tracking.
        </Typography>
        
        <Divider sx={{ margin: '20px 0' }} />

        <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', color: '#333' }}>
          <HealthAndSafety sx={{ verticalAlign: 'middle', marginRight: 1 }} /> What is the Issue Robur Resolves?
        </Typography>
        <Typography paragraph sx={{ lineHeight: 1.6 }}>
          Robur tackles two core issues: the challenge of managing a balanced diet, especially for people struggling with eating disorders, and the financial difficulties associated with affording healthy food. A significant portion of the population struggles with disordered eating. Research indicates that over 30 million people in the U.S. suffer from some type of eating disorder (National Eating Disorders Association), highlighting a major health crisis. On the financial side, healthy eating is about 30% more expensive compared to cheaper, less nutritious food options (Harvard T.H. Chan School of Public Health).
          Robur solves these problems by offering a two-fold solution: personalized, accessible nutritional advice and meal tracking for individuals struggling with food management, while also providing budgeting tools for low-income families. The AI-driven recommendations help users strike a balance between health and affordability, making a healthier lifestyle more accessible.
        </Typography>

        <Divider sx={{ margin: '20px 0' }} />

        <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', color: '#333' }}>
          <QuestionMark sx={{ verticalAlign: 'middle', marginRight: 1 }} /> Q&A Center:
        </Typography>
        <Box sx={{ marginBottom: 2 }}>
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
            Q: Why should I use Robur?
          </Typography>
          <Typography paragraph sx={{ lineHeight: 1.6 }}>
            A: Robur is designed to support anyone striving for better health, whether through improved eating habits, budgeting, or workout tracking. It is especially valuable if you're managing a specific dietary goal, navigating the complexities of disordered eating, or facing financial challenges related to food. Its AI-powered insights give you the control to balance nutrition with affordability.
          </Typography>
        </Box>

        <Box sx={{ marginBottom: 2 }}>
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
            Q: What makes Robur different from other meal tracking apps?
          </Typography>
          <Typography paragraph sx={{ lineHeight: 1.6 }}>
            A: Unlike most meal trackers that focus solely on nutrition, Robur adds financial management to the equation. It’s one of the few apps that empowers you to track meals based on both health and budget goals, making it an essential tool for those who want more holistic lifestyle management.
          </Typography>
        </Box>

        <Box sx={{ marginBottom: 2 }}>
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
            Q: Should I use Robur if I don't have any specific dietary restrictions?
          </Typography>
          <Typography paragraph sx={{ lineHeight: 1.6 }}>
            A: Yes! Even if you don’t have strict dietary needs, Robur’s recommendations can help optimize your meals for better overall health, saving you money in the process. Plus, it’s great for tracking your fitness journey as you can combine its nutritional insights with workout tracking.
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} sx={{ bgcolor: '#009688', color: '#fff' }}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AboutUs;
