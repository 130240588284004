// src/mockAuthService.js

const users = [
    { username: 'user1', password: 'password1' },
    { username: 'user2', password: 'password2' },
    { username: 'user3', password: 'password3' },
  ];
  
  export const login = async ({ email, password }) => {
    const response = await fetch('https://smartlink.ajna.cloud/nutriweatlh/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, password }),
    });
    debugger
    const data = await response.json();
    if (response.ok) {
      return { success: true, message: 'Login successful' };
    } else {
      return { success: false, message: 'Login failed' };
    }
  };
  
export const register = async ({ email, password, name, age, gender }) => {
  const userExists = users.some((user) => user.email === email);
  
  if (userExists) {
    return { success: false, message: 'User already exists' };
  } else {
    try {
      const response = await fetch('https://smartlink.ajna.cloud/nutriweatlh/registerUser', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password, name, age, gender }),
      });
      
      const data = await response.json();
      
      if (response.ok) {
        users.push({ email, password, name, age, gender });
        return { success: true, message: 'Registration successful', data };
      } else {
        return { success: false, message: 'Registration failed', data };
      }
    } catch (error) {
      return { success: false, message: 'An error occurred', error };
    }
  }
};

  