import { useState } from 'react';
import { Box, Typography } from '@mui/material';

const ImagePreview = ({ imagePreview }) => {
  const [isFullScreen, setIsFullScreen] = useState(false);

  const handleClick = () => {
    setIsFullScreen(!isFullScreen); // Toggle full-screen mode
  };

  return (
    <>
      {imagePreview && ( // Render image preview if available
        <Box sx={{ mt: 2, textAlign: 'center' }}>
          <Typography variant="h6" gutterBottom>
            Preview
          </Typography>
          <div
            onClick={handleClick} // Click to expand/collapse image
            style={{
              display: 'inline-block',
              cursor: 'pointer',
              border: '1px solid #009688',
              borderRadius: '4px',
              position: isFullScreen ? 'fixed' : 'relative',
              top: 0,
              left: 0,
              zIndex: isFullScreen ? 2000 : 'auto', // Higher zIndex for full-screen
              backgroundColor: isFullScreen ? 'rgba(0, 0, 0, 0.8)' : 'transparent', // Dark background when full-screen
              width: isFullScreen ? '100%' : 'auto', // Full width when in full-screen
              height: isFullScreen ? '100%' : 'auto', // Full height when in full-screen
              overflow: 'hidden', // Hide overflow
            }}
          >
            <img
              src={imagePreview}
              alt="Preview"
              style={{
                maxWidth: isFullScreen ? '100%' : '100%', // Adjust for full-screen
                maxHeight: isFullScreen ? '100%' : '300px', // Adjust for full-screen
                transition: 'transform 0.2s ease', // Smooth transition for image
                transform: isFullScreen ? 'scale(1)' : 'scale(1)', // No scale effect for simplicity
              }}
            />
          </div>
        </Box>
      )}
    </>
  );
};

export default ImagePreview;
