import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, IconButton, Menu, MenuItem, Avatar, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './Header.css';
import AboutUs from './About_Us';

const Header = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isAboutUsOpen, setAboutUsOpen] = useState(false); // State to control About Us modal
  const navigate = useNavigate();

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    handleMenuClose();
    navigate('/login');
  };

  const handleLogoClick = () => {
    setAboutUsOpen(true); // Open About Us modal
  };

  const handleAboutUsClose = () => {
    setAboutUsOpen(false); // Close About Us modal
  };

  return (
    <>
      <AppBar position="fixed" className="header" sx={{ bgcolor: "#009688", height: { xs: "80px", sm: "100px" } }}>
        <Toolbar className="toolbar" sx={{ minHeight: { xs: "80px", sm: "100px" } }}>
          <Box className="logo-section" onClick={handleLogoClick}>
            <img
              src="static/nutriwealth_logo.jpeg"
              alt="Logo"
              className="header-logo"
              style={{ cursor: 'pointer' }} // Change cursor to pointer for logo
            />
          </Box>

          <IconButton onClick={handleMenuOpen} color="inherit">
            <Avatar alt="User Profile" />
          </IconButton>
          <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
            <MenuItem onClick={handleMenuClose}>User Preferences</MenuItem>
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      
      {/* About Us Modal */}
      <AboutUs open={isAboutUsOpen} onClose={handleAboutUsClose} />
    </>
  );
};

export default Header;
