import React from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
} from '@mui/material';

const FoodAnalysis = ({ analyzedArtifactData }) => {
  return (
    <Box mt={8}>
      <Typography variant="h4" component="h2" gutterBottom>
        Detailed Analysis
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Card sx={{ height: '100%', borderRadius: 2 }}>
            <CardHeader
              title="Meal Summary"
              sx={{ bgcolor: '#009688', color: 'white', textAlign: 'center' }}
            />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1">
                    <strong>Type:</strong> {analyzedArtifactData?.meal_summary?.meal_type}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1">
                    <strong>Date:</strong> {analyzedArtifactData?.meal_summary?.date}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1">
                    <strong>Time:</strong> {analyzedArtifactData?.meal_summary?.time}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1">
                    <strong>Day:</strong> {analyzedArtifactData?.meal_summary?.day} ({analyzedArtifactData?.meal_summary?.weekday_or_weekend})
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1">
                    <strong>Overall Rating:</strong> {analyzedArtifactData?.meal_summary?.overall_meal_rating}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" paragraph>
                    <strong>Suggestion:</strong> {analyzedArtifactData?.meal_summary?.meal_suggestion || 'N/A'}
                  </Typography>
                </Grid>
              </Grid>

              <Divider sx={{ my: 2 }} />

              <Typography variant="h6" align="center" sx={{ mb: 1 }}>Total Nutrition</Typography>
              <Grid container spacing={1}>
                {Object.entries(analyzedArtifactData?.meal_summary?.total_nutrition).map(([key, value]) => (
                  <Grid item xs={6} key={key}>
                    <Typography variant="body2">
                      <strong>{key}:</strong> {value}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card sx={{ height: '100%', borderRadius: 2 }}>
            <CardHeader
              title="Nutrition Focus"
              sx={{ bgcolor: '#009688', color: 'white', textAlign: 'center' }}
            />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1">
                    <strong>High:</strong> {analyzedArtifactData?.nutrition_focus?.nutrients_high.length > 0 ? analyzedArtifactData?.nutrition_focus?.nutrients_high.join(', ') : 'None'}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1">
                    <strong>Low:</strong> {analyzedArtifactData?.nutrition_focus?.nutrients_low.length > 0 ? analyzedArtifactData?.nutrition_focus?.nutrients_low.join(', ') : 'None'}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" paragraph>
                    <strong>Suggestion:</strong> {analyzedArtifactData?.nutrition_focus?.suggestion || 'N/A'}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card sx={{ height: '100%', borderRadius: 2 }}>
            <CardHeader
              title="Dietary Preferences"
              sx={{ bgcolor: '#009688', color: 'white', textAlign: 'center' }}
            />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1">
                    <strong>Vegetarian:</strong> {analyzedArtifactData?.dietary_preferences?.is_vegetarian ? 'Yes' : 'No'}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1">
                    <strong>Non-Vegetarian:</strong> {analyzedArtifactData?.dietary_preferences?.is_non_vegetarian ? 'Yes' : 'No'}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1">
                    <strong>Meat Preference:</strong> {analyzedArtifactData?.dietary_preferences?.meat_preference || 'N/A'}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1">
                    <strong>Allergies:</strong> {analyzedArtifactData?.dietary_preferences?.allergies.length > 0 ? analyzedArtifactData?.dietary_preferences?.allergies.join(', ') : 'None'}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card sx={{ height: '100%', borderRadius: 2 }}>
            <CardHeader
              title="Health Assessment"
              sx={{ bgcolor: '#009688', color: 'white', textAlign: 'center' }}
            />
            <CardContent>
              <Grid container spacing={2}>
                {Object.keys(analyzedArtifactData?.health_assessment).map((key) => (
                  <Grid item xs={12} sm={6} key={key}>
                    <Card variant="outlined" sx={{ borderRadius: 2, padding: 2 }}>
                      <Typography variant="h6" align="center" sx={{ mb: 1 }}>
                        {key.charAt(0).toUpperCase() + key.slice(1)}
                      </Typography>
                      <Divider sx={{ mb: 2 }} />
                      <Typography variant="body1">
                        <strong>Rating:</strong> {analyzedArtifactData?.health_assessment[key].rating}
                      </Typography>
                      <Typography variant="body1" paragraph>
                        <strong>Suggestion:</strong> {analyzedArtifactData?.health_assessment[key].suggestion}
                      </Typography>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card sx={{ height: '100%', borderRadius: 2 }}>
            <CardHeader
              title="Food Items"
              sx={{ bgcolor: '#009688', color: 'white', textAlign: 'center' }}
            />
            <CardContent>
              <Grid container spacing={2}>
                {analyzedArtifactData?.food_items.map((item, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <Card variant="outlined" sx={{ height: '100%', borderRadius: 2 }}>
                      <CardContent>
                        <Typography variant="h6" align="center" sx={{ mb: 1 }}>
                          {item.name}
                        </Typography>
                        <Divider sx={{ mb: 2 }} />
                        <Typography variant="body2">
                          <strong>Serving Size:</strong> {item.serving_size}
                        </Typography>
                        <Divider sx={{ my: 2 }} />
                        <Grid container spacing={1} sx={{ mb: 2 }}>
                          {Object.entries(item.nutrition_values).map(([key, value]) => (
                            <Grid item xs={6} key={key}>
                              <Typography variant="body2">
                                <strong>{key}:</strong> {value}
                              </Typography>
                            </Grid>
                          ))}
                        </Grid>
                        <Divider sx={{ my: 2 }} />
                        <Grid container spacing={1}>
                          <Grid item xs={6}>
                            <Typography variant="body2">
                              <strong>Vegetarian:</strong> {item.flags.vegetarian ? 'Yes' : 'No'}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="body2">
                              <strong>Contains Allergens:</strong> {item.flags.contains_allergens ? 'Yes' : 'No'}
                            </Typography>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FoodAnalysis;
