import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  TableCell,
  TableRow,
  TextField,
  Alert,
  Snackbar,
  Grid,
  Paper
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import FullPageLoader from "./FullPageLoader";
import "./Profile.css";
import FoodAnalysis from "./Food_Analysis";
import Receipt_Analysis from "./Receipt_Analysis";
import ImagePreview from "./Image_Preview";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: "bold",
  backgroundColor: theme.palette.grey[200],
}));

const StyledTableRow = styled(TableRow)(({ theme, index }) => ({
  backgroundColor: index % 2 === 0 ? theme.palette.action.hover : "inherit",
}));


const Profile = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [open, setOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [analyzedArtifactData, setAnalyzedArtifactData] = useState({});
  const [healthConditions, setHealthConditions] = useState("")
  const [dietType, setDietType] = useState("")
  const [foodAllergies, setFoodAllergies] = useState("")
  const [analysisType, setAnalysisType] = useState("")
  const [imagePreview, setImagePreview] = useState(null); // State for image preview

  const navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith('image/')) {
      setSelectedFile(file);
      const fileURL = URL.createObjectURL(file); // Create a URL for the image file
      setImagePreview(fileURL); // Set the image preview URL
    } else {
      alert('Please upload an image file');
    }
  };

  const getPresignedURL = async (bodyData) => {
    try {
      const response = await fetch('https://smartlink.ajna.cloud/nutriweatlh/genPresignedURL', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(bodyData),
      });
  
      if (!response.ok) {
        throw new Error('Failed to get presigned URL');
      }
  
      return await response.json();
    } catch (error) {
      console.error('Error getting presigned URL:', error);
      throw error;
    }
  };
  
  const uploadFileToS3 = async (presignedURL, file) => {
    const formData = new FormData();
    formData.append('file', file);
  
    try {
      const response = await fetch(presignedURL, {
        method: 'PUT',
        headers: {
          'Content-Type': file.type,
          'Access-Control-Allow-Origin': '*'
        },
        body: file,
        mode: 'cors',
      });
  
      if (!response.ok) {
        throw new Error('Error uploading file');
      }
  
      return response;
    } catch (error) {
      console.error('Error uploading file:', error);
      throw error;
    }
  };

  const analyzeArtifact = async (payload) => {  
    try {
      const response = await fetch('https://smartlink.ajna.cloud/nutriweatlh/analyseArtifact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload),
        mode: 'cors',
      });
  
      if (!response.ok) {
        throw new Error('Failed to get presigned URL');
      }
  
      return await response.json();
    } catch (error) {
      console.error('Error uploading file:', error);
      throw error;
    }
  };
  
  const handleUpload = async () => {
    if (!selectedFile) {
      alert('Please select a file to upload');
      return;
    }
  
    setIsLoading(true);
  
    const bodyDataForPut = {
      object_name: selectedFile.name,
      operation_mode: "put",
      username: "pnalla@gmail.com"
    };

    const bodyDataForGet = {
      object_name: selectedFile.name,
      operation_mode: "get",
      username: "pnalla@gmail.com"
    };
  
    try {
      const presignedData = await getPresignedURL(bodyDataForPut);
      console.log('Presigned data:', presignedData);
  
      await uploadFileToS3(presignedData.presignedURL, selectedFile);
  
      const presignedDataAfterUpload = await getPresignedURL(bodyDataForGet);
      const analyzeArtifactPayload = {
        "image_url": presignedDataAfterUpload.presignedURL,
        "payload": {
          "health_conditions": healthConditions,
          "diet_type": dietType,
          "food_allergies": foodAllergies
        }
      }
      const analyzedArtifactDataNew = await analyzeArtifact(analyzeArtifactPayload);
      debugger
      setAnalyzedArtifactData(analyzedArtifactDataNew)
      setIsVisible(true);
      if(analyzedArtifactDataNew?.merchant) {
        setAnalysisType("receipt");
      } else {
        setAnalysisType("food");
      }
      
      console.log(analyzedArtifactData);
      setIsLoading(false);
      setSuccess('File uploaded successfully');
      setOpen(true);
      console.log('File uploaded successfully');
    } catch (error) {
      setIsLoading(false);
      setError(error.message);
      setOpen(true);
    }
  };
  

  const handleDetails = (index) => {
    navigate(`/details/${index}`);
  };

  return (
    <>
      <Header />
      <Box
        style={{
          display: "flex",
          height: "calc(100vh - 64px)", // Full screen height minus header height
          paddingTop: "64px", // Space for the header
          paddingBottom: "48px", // Space for the footer
        }}
      >
        {/* Right Section for Upload and Table */}
        <Box
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            padding: "20px",
            paddingBottom: "20px",
            marginTop: "5%",
            marginBottom: "5%",
            overflowY: "auto",
          }}
        >
          <Paper
            elevation={3} // Adjust the elevation for shadow depth
            sx={{
              padding: 2, // Add padding inside the Paper
              borderRadius: 2, // Optional: to make the corners rounded
              border: '1px solid #009688', // Optional: to add a border
            }}
          >
            <Box>
              <Typography variant="h6" gutterBottom>
                Upload a File
              </Typography>
              <input
                accept="image/*"
                style={{ display: "none" }}
                id="file-upload"
                type="file"
                capture="camera"
                onChange={handleFileChange}
              />
              <label htmlFor="file-upload">
                <Button variant="contained" sx={{ backgroundColor: '#009688', color: '#fff' }} component="span">
                  Choose File
                </Button>
              </label>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                disabled
                value={selectedFile ? selectedFile.name : ""}
                sx={{ mt: 2 }}
              />
              <Grid container spacing={2} sx={{ mt: 2 }}>
                <Grid item xs={12} md={4}>
                  <TextField
                    label="Health Conditions"
                    variant="outlined"
                    fullWidth
                    value={healthConditions}
                    onChange={(e) => setHealthConditions(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    label="Diet Type"
                    variant="outlined"
                    fullWidth
                    value={dietType}
                    onChange={(e) => setDietType(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    label="Food Allergies"
                    variant="outlined"
                    fullWidth
                    value={foodAllergies}
                    onChange={(e) => setFoodAllergies(e.target.value)}
                  />
                </Grid>
              </Grid>
              <Button
                variant="contained"
                sx={{ backgroundColor: '#009688', color: '#fff', mt: 2 }}
                onClick={handleUpload}
                disabled={!selectedFile}
              >
                Upload
              </Button>
              {isLoading && <FullPageLoader />}
              <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                {error ? (
                  <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
                    {error}
                  </Alert>
                ) : (
                  <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
                    {success}
                  </Alert>
                )}
              </Snackbar>
            </Box>
          </Paper>

          <ImagePreview imagePreview={imagePreview} />


          {(isVisible && analysisType === "food") ? 
          (
            <FoodAnalysis analyzedArtifactData={analyzedArtifactData} />
          ) : (<div></div>)
        }

        {(isVisible && analysisType === "receipt") ? (
            <Receipt_Analysis analyzedArtifactData={analyzedArtifactData} />
          ) : (<div></div>)
        }

      </Box>
    </Box>
    <Footer />
  </>
);
};

export default Profile;
