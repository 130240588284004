import React, { useState } from 'react';
import { Button, TextField, Box, Typography, Alert, FormControl } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { useNavigate } from 'react-router-dom';
import { register } from '../services/mockAuthService';
import './Register.css'; // Import the CSS file

const genderOptions = [
  { label: 'Male' },
  { label: 'Female' },
  { label: 'Other' },
];

const Register = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [age, setAge] = useState('');
  const [gender, setGender] = useState(null);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const navigate = useNavigate();

  const handleRegister = async (e) => {
    e.preventDefault();
  
    if (password !== confirmPassword) {
      setError("Password and Confirm Password should be the same");
    } else {
      try {
        const response = await register({ email, password, name, age, gender: gender ? gender.label : '' });
        
        if (response.success) {
          setSuccess(response.message);
          setError('');
          setTimeout(() => {
            navigate('/login');
          }, 1500);
        } else {
          setError(response.message);
          setSuccess('');
        }
      } catch (error) {
        setError('An error occurred during registration');
        setSuccess('');
      }
    }
  };

  return (
    <Box className="register-container">
      <Box 
        component="img" 
        src="static/nutriwealth_logo.jpeg" 
        alt="App Logo" 
        className="logo" 
      />

      <Box className="form-container">
        <Typography variant="h4" className="form-title">Register</Typography>
        {error && <Alert severity="error">{error}</Alert>}
        {success && <Alert severity="success">{success}</Alert>}
        <Box component="form" onSubmit={handleRegister} className="register-form">
          <TextField
            label="Name"
            variant="outlined"
            fullWidth
            margin="normal"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            margin="normal"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            label="Age"
            type="number"
            value={age}
            onChange={(e) => setAge(e.target.value)}
            fullWidth
            margin="normal"
            InputProps={{ inputProps: { min: 0 } }}
          />
          <FormControl fullWidth margin="normal" variant="outlined">
            <Autocomplete
              options={genderOptions}
              getOptionLabel={(option) => option.label}
              onChange={(event, newValue) => {
                setGender(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} label="Gender" variant="outlined" />
              )}
              style={{ width: '100%' }}
            />
          </FormControl>
          <TextField
            label="Password"
            type="password"
            variant="outlined"
            fullWidth
            margin="normal"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <TextField
            label="Confirm Password"
            type="password"
            variant="outlined"
            fullWidth
            margin="normal"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <Button type="submit" variant="contained" sx={{ backgroundColor: '#009688', color: '#fff' }} fullWidth>
            Register
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Register;
