import React from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
} from '@mui/material';

const Receipt_Analysis = ({ analyzedArtifactData }) => {
  return (
    <Box mt={8}>
          <Typography variant="h4" component="h2" gutterBottom>
            Detailed Receipt Analysis
          </Typography>
    
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Card sx={{ height: '100%', borderRadius: 2 }}>
                <CardHeader 
                  title="Receipt Summary" 
                  sx={{ bgcolor: '#009688', color: 'white', textAlign: 'center' }} 
                />
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body1">
                        <strong>Date:</strong> {analyzedArtifactData.transaction.date}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body1">
                        <strong>Time:</strong> {analyzedArtifactData.transaction.time}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body1">
                        <strong>Store:</strong> {analyzedArtifactData.merchant.store_name}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body1">
                        <strong>Total Amount:</strong> {analyzedArtifactData.currency} {analyzedArtifactData.total}
                      </Typography>
                    </Grid>
                  </Grid>
    
                  <Divider sx={{ my: 2 }} />
    
                  <Typography variant="h6" align="center" sx={{ mb: 1 }}>Items Purchased</Typography>
                  <Grid container spacing={1}>
                    {analyzedArtifactData.items.map((item, index) => (
                      <Grid item xs={12} key={index}>
                        <Typography variant="body2">
                          <strong>{item.name}:</strong> {item.quantity} @ {item.price} each
                        </Typography>
                      </Grid>
                    ))}
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
    
            <Grid item xs={12} md={6}>
              <Card sx={{ height: '100%', borderRadius: 2 }}>
                <CardHeader 
                  title="Payment Details" 
                  sx={{ bgcolor: '#009688', color: 'white', textAlign: 'center' }} 
                />
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body1">
                        <strong>Payment Method:</strong> {analyzedArtifactData.payment.method}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body1">
                        <strong>Card Last Digits:</strong> {analyzedArtifactData.payment.card_last_digits}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
    
            <Grid item xs={12}>
              <Card sx={{ height: '100%', borderRadius: 2 }}>
                <CardHeader 
                  title="Additional Information" 
                  sx={{ bgcolor: '#009688', color: 'white', textAlign: 'center' }} 
                />
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="body1">
                        <strong>Notes:</strong> {analyzedArtifactData.notes}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
  );
};

export default Receipt_Analysis;
