import React, { useState } from 'react';
import { Button, TextField, Box, Typography, Alert } from '@mui/material';
import { useNavigate, Link } from 'react-router-dom';
import { login } from '../services/mockAuthService';
import './Login.css'; // Import the CSS file

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await login({ email, password });
      
      if (response.success) {
        setSuccess(response.message);
        setError('');
        setTimeout(() => {
          navigate('/profile'); // Navigate to your desired route after success
        }, 1500);
      } else {
        setError(response.message);
        setSuccess('');
      }
    } catch (error) {
      setError('An error occurred during login');
      setSuccess('');
    }
  };

  return (
    <Box className="login-container">
      <Box 
        component="img" 
        src="static/nutriwealth_logo.jpeg" 
        alt="App Logo" 
        className="logo" 
      />

      <Box className="form-container">
        <Typography variant="h4" className="form-title">Login</Typography>
        {error && <Alert severity="error">{error}</Alert>}
        {success && <Alert severity="success">{success}</Alert>}
        <Box component="form" onSubmit={handleLogin} className="login-form">
          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            margin="normal"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            label="Password"
            type="password"
            variant="outlined"
            fullWidth
            margin="normal"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button type="submit" variant="contained" sx={{ backgroundColor: '#009688', color: '#fff' }} fullWidth>
            Login
          </Button>
        </Box>
        <Box className="register-link">
          <Typography variant="body2">
            Don't have an account?{' '}
            <Link to="/register" className="register-text">
              Register here
            </Link>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Login;
